<script lang="ts" setup>
import { useMutation, useQueryClient } from 'vue-query'
import type { AuctionItem, AuctionLayoutType } from '~/types/auction'
import { useAuctionItems } from '~/composables/api/useAuctionItems'
import { useAlertStore } from '~/stores/alert'

const props = defineProps<{
  modelValue: boolean
  auctionItem?: AuctionItem
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

const { createWinner } = useAuctionItems()
const alertStore = useAlertStore()
const { captureException } = useSentry()
const queryClient = useQueryClient()

const form = ref<any>({
  id: props.auctionItem?.id || undefined,
  purchaserFirstName: '',
  purchaseLastName: '',
  purchaseBid: 0
})

function closeModal() {
  emit('update:modelValue', false)
}

const { mutateAsync: onSubmit, isLoading } = useMutation({
  mutationFn: async () => {
    const { id, purchaserFirstName,purchaserLastName, purchaseBid } = form.value
    try {
      const response = id
        ? await createWinner(id, {
          purchaseBid,
          purchaserFirstName,
          purchaserLastName,
        }) : null

      await queryClient.invalidateQueries(['auctionItems'])
      alertStore.setAlert(`Successfully ${id ? 'updated' : 'created'} auction item`, 'success')
      closeModal()
    }
    catch (e) {
      captureException(e)
      alertStore.setAlert(`Couldn't ${id ? 'update' : 'create'} auction item`, 'error')
      console.error(e)
    }
  },
})

const layoutOptions: { label: string; value: AuctionLayoutType }[] = [
  {
    label: 'Horizontal | Horizontal (two rows)',
    value: 'two-horizontal',
  },
  {
    label: 'Horizontal | Vertical',
    value: 'horizontal-vertical',
  },
  {
    label: 'Vertical | Horizontal',
    value: 'vertical-horizontal',
  }, {
    label: 'Vertical | Vertical',
    value: 'two-vertical',
  },
]

const modalTitle = 'Winner Details'
</script>

<template>
  <CommonPopup :model-value="modelValue" :title="modalTitle" @update:model-value="$emit('update:modelValue', $event)">
    <ul>
      <li v-for="(winner, index) in props.auctionItem?.AuctionItemWinners" :key="index">
        <h2>{{ `${winner.purchaserFirstName} ${winner.purchaserLastName} - ${winner.purchaseBid}` }}</h2>
      </li>
    </ul>
    <FormKit type="form" :actions="false" @submit="onSubmit">
      <FormKit v-model="form.purchaserFirstName" type="text" label="First Name" />
      <FormKit v-model="form.purchaserLastName" type="text" label="Last Name" />
      <FormKit v-model="form.purchaseBid" type="number" label="Bid Amount" />
      <div class="grid lg:grid-cols-2 gap-4 mt-6">
        <CommonButton pre-icon="ri:close-line" :disabled="isLoading" type="button" theme="neutral-light" block @click="closeModal">
          Cancel
        </CommonButton>
        <CommonButton :loading="isLoading" type="submit" theme="green" post-icon="ri:check-line" block>
          Save
        </CommonButton>
      </div>
    </FormKit>
  </CommonPopup>
</template>
